.img {
    height: 200px;
    width: auto;
    margin: 15px;
}

.imagesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    flex-wrap: wrap;
}

.image {
    position: relative;
    border: 1px solid black;
}

.imageDelete {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    color: red;
    justify-content: center;
    align-items: center;
    font-size: 90px;
    display: none;
    opacity: 0;
}

.image:hover .imageDelete {
    display: flex;
    opacity: 1;
    cursor: pointer;
}
